/** Appends listeners to button {[listener_name]: (event) => ....} */
import {
  type IUploadDialog,
  type IAlertDialog,
  type IConfirmDialog,
  type ILogoutDialog,
  type ISnackbar,
  type IPromptDialog,
  type IVueDialog,
  type IDialog
} from './interfaces/index'
import {
  uploadDialogTemplate,
  alertDialogTemplate,
  snackbarDialogTemplate,
  confirmDialogTemplate,
  logoutDialogTemplate
} from './dialog_templates'
import { ascendantNodeByTag } from '../../global.utils'
import './elements.scss'
import { DOMEmit } from '../dom_utils'

const _avv_listen = (
  element: Element,
  listeners: {
    [key in keyof HTMLElementEventMap]?: (e: HTMLElementEventMap[key]) => void
  }
) => {
  Object.keys(listeners).forEach((eventName) =>
    element.addEventListener(eventName, (e) => listeners[eventName](e))
  )
}
window.avv_listen = _avv_listen

/** Map what is holding instance to element */
const _avv_weak_map = new WeakMap<
  HTMLUnknownElement,
  ReturnType<typeof create_weak_object>
>()
window.avv_weak_map = _avv_weak_map

type Unpacked<T> = T extends (infer U)[] ? U : T
const create_weak_object = (element: HTMLUnknownElement) => {
  const listeners: Record<string, any> = {
    open: [] as Array<(event: Event) => void>,
    close: [] as Array<(event: Event) => void>,
    select: [] as Array<(option: HTMLUnknownElement) => void>
  }
  return {
    $: element,
    addListener<
      TName extends keyof typeof listeners,
      TCallback extends Unpacked<(typeof listeners)[TName]>
    >(name: TName, callback: TCallback) {
      this.listeners[name] ??= []
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.listeners[name].push(callback)
    },
    fireEvent<
      TName extends keyof typeof listeners,
      TCallback extends Unpacked<(typeof listeners)[TName]>
    >(name: TName, ...args: Parameters<TCallback>) {
      if (this.listeners[name] == null) return
      this.listeners[name].forEach((e) => e(...args))
    },
    listeners
  }
}

// +++++++++++++++++++++++++++++++ avv-dialog

const _isAlert = (options: any): options is IAlertDialog => {
  return options.alertMessage !== undefined
}
const _isPrompt = (options: any): options is IPromptDialog => {
  return options.promptMessage !== undefined
}
const _isConfirm = (options: any): options is IConfirmDialog => {
  return options.confirmMessage !== undefined
}
const _isSnackbar = (options: any): options is ISnackbar => {
  return options.snackMessage !== undefined
}
const _isUpload = (options: any): options is IUploadDialog => {
  return options.uploadTitle !== undefined
}
const _isLogout = (options: any): options is ILogoutDialog => {
  return options.loggedOut !== undefined
}
const _isVueDialog = (options: any): options is IVueDialog => {
  return options.vueDialog !== undefined
}

const _avv_dialog_manager = new (class DialogManager {
  currentDialog: IDialog | null = null
  queue: Array<IDialog> = []
  template: HTMLElement | null = null
  elementsToShow: HTMLElement[] = []
  triggeringElement: HTMLElement | null = null
  lastClickedElement: HTMLElement | null = null

  constructor() {
    this._init_capture_of_trigger()
  }

  next() {
    if (this.queue.length > 0) return this.queue.pop()
  }

  _showDialog(dialog: IDialog | undefined | null) {
    if (dialog == null) return

    this.currentDialog = dialog

    if (_isAlert(dialog)) {
      if (dialog.alertButton === undefined) dialog.alertButton = 'OK'
      if (dialog.alertTitle === undefined) dialog.alertTitle = 'Avvoka Alert'

      this.template = document.createElement('div')
      this.template.classList.add('avv_dialog-wrapper')
      this.template.innerHTML = alertDialogTemplate(dialog)

      this.template.querySelector('.avv-button').addEventListener(
        'click',
        () => {
          if (dialog.alertCallback !== undefined) dialog.alertCallback()
          this.template.remove()
          this.template = null
          this.currentDialog = null
          this._showDialog(this.next())
        },
        { once: true }
      )

      document.body.appendChild(this.template)
    } else if (_isConfirm(dialog)) {
      this.template = document.createElement('div')
      this.template.classList.add('avv_dialog-wrapper')
      this.template.innerHTML = confirmDialogTemplate(dialog)
      const confirmInput = this.template.querySelector('textarea')

      const yesClick = () => {
        if (dialog.confirmCallback !== undefined)
          dialog.confirmCallback(true, confirmInput?.value)
        this.template.remove()
        this.template = null
        this.currentDialog = null
        this._showDialog(this.next())
      }

      const noClick = () => {
        if (dialog.confirmCallback !== undefined)
          dialog.confirmCallback(false, confirmInput?.value)
        this.template.remove()
        this.template = null
        this.currentDialog = null
        this._showDialog(this.next())
        this._handleBackground(false)
      }

      const closeClick = () => {
        if (dialog.confirmCallback !== undefined)
          dialog.confirmCallback(null, confirmInput?.value, true)
        this.template.remove()
        this.template = null
        this.currentDialog = null
        this._showDialog(this.next())
        this._handleBackground(false)
      }

      const yesButton = this.template.querySelector('.yes')
      const noButton = this.template.querySelector('.no')
      const closeButton = this.template.querySelector('.close')
      const options = { once: true }

      yesButton?.addEventListener('click', yesClick.bind(this), options)
      noButton?.addEventListener('click', noClick.bind(this), options)
      closeButton?.addEventListener('click', closeClick.bind(this), options)

      document.body.appendChild(this.template)
    } else if (_isPrompt(dialog)) {
      //TODO(sionzee) prompt requires async handling
    } else if (_isUpload(dialog)) {
      this.template = document.createElement('div')
      this.template.classList.add('avv_dialog-wrapper')
      this.template.innerHTML = uploadDialogTemplate(dialog)
      const dropzone = new Dropzone(this.template.querySelector('.upload'), {
        maxFilesize: 256, // Set the maximum file size to 256 MB
        createImageThumbnails: false,
        previewsContainer: false,
        addRemoveLinks: false, // Don't show remove links on dropzone itself.
        dictDefaultMessage: '',
        headers: {
          'X-CSRF-TOKEN': document
            .querySelector("meta[name='csrf-token']")
            .getAttribute('content')
        },
        ...dialog.dropzoneOptions
      })

      const dialogInstance = {
        el: this.template,
        closeDialog: () => {
          this.template?.remove()
          this.template = null
          this.currentDialog = null
          this._handleBackground(false)
          this._showDialog(this.next())
        }
      }

      this.template.querySelector('.cancel').addEventListener(
        'click',
        () => {
          dialogInstance.closeDialog()
        },
        { once: true }
      )

      document.body.appendChild(this.template)

      const spinner = document.createElement('div')
      spinner.className = 'spinner'
      spinner.innerHTML = `<div class="spinner-wrapper"><div class="rotator"><div class="inner-spin"></div><div class="inner-spin"><div role="status" class="visually-hidden">Uploading file</div></div></div></div>`
      const paragraph = dialogInstance.el.querySelector('.upload p')

      dropzone.on('sending', function (file, xhr, formData) {
        dialog.formDataModifier?.(formData)
      })

      dropzone.on('processing', function (data, response) {
        if (data.status === 'uploading') {
          paragraph.appendChild(spinner)
          const uploadButton = document.querySelector('.upload.dz-clickable')
          if (!uploadButton) {
            console.warn('Upload button not found')
            return
          }
          uploadButton.setAttribute('aria-label', 'Uploading file, please wait')
          const statusLoadingElement = document.createElement('div')
          statusLoadingElement.setAttribute('id', 'statusLoading')
          statusLoadingElement.setAttribute('role', 'status')
          statusLoadingElement.classList.add('hidden')
          statusLoadingElement.innerText = 'Uploading file'
          document.body.appendChild(statusLoadingElement)
        }

        dialog.uploadProcessCallback?.(dialogInstance, data, response)
      })
      dropzone.on('success', function (data, response) {
        if (data.status === 'success') {
          const statusLoadingElement = document.getElementById('statusLoading')
          const statusDoneElement = document.createElement('div')
          statusDoneElement.setAttribute('role', 'status')
          statusDoneElement.innerText = 'File uploaded successfully'
          document.body.appendChild(statusDoneElement)
          setTimeout(() => {
            statusDoneElement.remove()
          }, 3000)
        }

        dialog.uploadSuccessCallback?.(dialogInstance, data, response)
      })
      dropzone.on('error', function (data, response) {
        dialog.uploadErrorCallback?.(dialogInstance, data, response)
      })

      dropzone.on('uploadprogress', function (data, progress, bytesSent) {
        dialog.uploadProgressCallback?.(
          dialogInstance,
          data,
          progress,
          bytesSent
        )
      })
    } else if (_isSnackbar(dialog)) {
      const template = document.createElement('div')
      template.style.zIndex = '100'
      template.innerHTML = snackbarDialogTemplate(dialog)
      template.firstElementChild.classList.add(dialog.snackStyle)
      document.body.appendChild(template)

      const closeDialog = () => {
        template?.remove()
        if (this.currentDialog === dialog) {
          this.currentDialog = null
          this._showDialog(this.next())
        }
        if (dialog.onHide) {
          dialog.onHide()
        }
      }

      setTimeout(closeDialog, 6000)
    } else if (_isLogout(dialog)) {
      this.template = document.createElement('div')
      this.template.classList.add('avv_dialog-wrapper')
      this.template.innerHTML = logoutDialogTemplate(dialog)

      const closeDialog = () => {
        this.template?.remove()
        this.template = null
        this.currentDialog = null
        this._handleBackground(false)
        this._showDialog(this.next())
      }

      this.template.querySelector('.refresh')?.addEventListener('click', () => {
        closeDialog()
        DOMEmit('logout:keepalive')
      })

      document.body.appendChild(this.template)
    } else if (_isVueDialog(dialog)) {
      const app = Vue.createApp(dialog.vueDialog, dialog.props)

      const closeDialog = () => {
        this.template?.remove()
        this.template = null
        this.currentDialog = null
        this._handleBackground(false)
        this._showDialog(this.next())
      }

      this.template = document.createElement('div')
      const instance = app.mount(this.template)
      instance.$.vnode.props = {
        onCallback: (...args: any[]) => {
          dialog.callback(...args)
          closeDialog()
        }
      }
      document.body.appendChild(this.template)
    }
    if (!_isSnackbar(dialog)) {
      this._handleBackground()
      this._setFocusInsideDialog()
    }
  }

  openDialog(dialog: IDialog): any {
    setTimeout(() => (this.triggeringElement = this.lastClickedElement), 0)
    if (this.currentDialog !== null) {
      this.queue.push(dialog)
    } else {
      this._showDialog(dialog)
    }
  }

  _handleBackground(hide = true) {
    if (hide) {
      const main = document.querySelector('main')
      const nav = document.querySelector('nav')
      const elementsToHide = [
        ...this.getKeyboardFocusableElements(main),
        ...this.getKeyboardFocusableElements(nav)
      ] as HTMLElement[]
      this.elementsToShow = elementsToHide
      const makeNotFocusable = (element: HTMLElement) => {
        if (!element || this._isEditorContent(element)) return
        element.setAttribute('aria-hidden', 'true')
        element.setAttribute('tabindex', '-1')
      }
      elementsToHide.forEach(makeNotFocusable)
    } else {
      const makeFocusable = (element: HTMLElement) => {
        if (!element || this._isEditorContent(element)) return
        element.setAttribute('aria-hidden', 'false')
        element.setAttribute('tabindex', '1')
      }
      this.elementsToShow.forEach(makeFocusable)
      this.elementsToShow = []
      if (this.triggeringElement) {
        this.triggeringElement.focus()
        this.triggeringElement = null
      }
    }
  }

  _isEditorContent(element: HTMLElement | null): boolean {
    if (!element) return false
    if (element.classList.contains('avv-container')) return true
    if (element.tagName.toLowerCase() === 'main') return false
    else return this._isEditorContent(element.parentElement)
  }

  _setFocusInsideDialog() {
    const elemToFocus = this.getKeyboardFocusableElements(
      this.template
    )[0] as HTMLElement
    if (elemToFocus) elemToFocus.focus()
  }

  getKeyboardFocusableElements(element: HTMLElement | null) {
    if (!element) return []
    return Array.from(
      element.querySelectorAll(
        'a, button, input, textarea, select, details,[tabindex]:not([tabindex="-1"]), [contenteditable]'
      )
    ).filter((el) => !el.hasAttribute('disabled'))
  }

  _init_capture_of_trigger() {
    window.addEventListener('click', (e) => {
      this.lastClickedElement = e.target as HTMLElement
    })
  }
})()
window.avv_dialog_manager = _avv_dialog_manager

type DialogExecution = {
  (options: IAlertDialog): void
  (options: IPromptDialog): string
  (options: IConfirmDialog): boolean
  /** @deprecated Please use useToast */
  (options: ISnackbar): void
  (options: IUploadDialog): void
  /** @deprecated Please use useDialog */
  (options: IVueDialog): void
}

const _avv_dialog: DialogExecution = (
  options:
    | IAlertDialog
    | IPromptDialog
    | IConfirmDialog
    | ISnackbar
    | IUploadDialog
    | IVueDialog
) => {
  return window.avv_dialog_manager.openDialog(options)
}
window.avv_dialog = _avv_dialog

// ------------------------------- avv-dialog

// +++++++++++++++++++++++++++++++ avv-select
/*
 *  Unfortunately the <select>, <option> elements cannot be fully styled.
 *  So we have to replace it with our solution.
 *  */

/**
 * @usage: <avv-select><avv-option>ITEM A</avv-option></avv-select>
 * */

const _avv_open_set = new Set()
window.avv_open_set = _avv_open_set

/** Register default behaviour for the button */
const _avv_select_register = (
  selectElement: HTMLUnknownElement,
  init = (
    select: HTMLUnknownElement,
    instance: ReturnType<typeof create_weak_object>
  ) => {}
) => {
  /** is already registered */
  if (window.avv_weak_map.has(selectElement)) return
  window.avv_weak_map.set(selectElement, create_weak_object(selectElement))
  init(selectElement, window.avv_weak_map.get(selectElement))

  const instance = window.avv_weak_map.get(selectElement)

  const clickOutside = (e) => {
    if (e.button !== 0) return

    const target = e.target
    const isAvvOption = (target) => target.tagName === 'AVV-OPTION'
    if (selectElement.contains(target)) {
      if (!isAvvOption(target)) return
      const findOption = (temp) => {
        if (!selectElement.contains(temp)) return null
        if (temp.tagName === 'AVV-OPTION') return temp
        return findOption(temp.parentNode)
      }

      const option = findOption(target)
      if (option == null) {
        return
      }

      // To prevent click event behind the menu
      e.stopImmediatePropagation()

      window.avv_select_option(option)
      const isDisabled = option.classList.contains('disabled')
      const isMultiSelect = selectElement.getAttribute('multi') === 'true'
      if (!isDisabled && !isMultiSelect)
        window.avv_select_close(selectElement, e)
    } else {
      window.avv_select_close(selectElement, e)
    }
  }

  const keyDown = (e) => {
    if (e.code.indexOf('Esc') > -1) {
      window.avv_select_close(selectElement, e)
    }
  }

  const menu = selectElement.querySelector('.menu')
  if (menu) {
    const onMenuScroll = (elem) => {
      const isOnBottom =
        elem.scrollHeight - elem.scrollTop === elem.clientHeight
      if (isOnBottom) instance.fireEvent('end')
    }

    menu.addEventListener('scroll', (e) => onMenuScroll(e.target))
  }

  const initListenersAndFocus = () => {
    window.addEventListener('mousedown', clickOutside)
    window.addEventListener('keydown', keyDown)
    ;(
      selectElement.querySelector("input[type='search']") as HTMLInputElement
    )?.focus()
  }

  const removeListeners = () => {
    window.removeEventListener('mousedown', clickOutside)
    window.removeEventListener('keydown', keyDown)
  }

  const isFrozen = () => {
    return selectElement.getAttribute('frozen') === 'true'
  }

  instance.addListener('open', initListenersAndFocus)

  instance.addListener('close', removeListeners)

  window.avv_listen(selectElement, {
    click: (e) => {
      if (isFrozen()) return

      const target = e.target as Node
      const menu = selectElement.querySelector('.menu')
      if (!menu.contains(target)) window.avv_select_toggle(selectElement, e)
    },
    keydown: (e) => {
      if (isFrozen()) return

      if (e.code === 'Enter') {
        if (e.target.tagName.toLowerCase() === 'avv-option')
          window.avv_select_option(e.target, false, selectElement)
        window.avv_select_toggle(selectElement, e)
      }
    }
  })

  if (selectElement.getAttribute('multi') !== 'true')
    window.avv_select_option(
      window.avv_select_get_selected(selectElement),
      true,
      selectElement
    )
  window.avv_weak_map.get(selectElement).fireEvent('after_init', selectElement)
}
window.avv_select_register = _avv_select_register

const _avv_select_get_selected = (selectElement) => {
  return selectElement.querySelector('avv-option[selected="true"]')
}
window.avv_select_get_selected = _avv_select_get_selected

const _avv_select_find_option = (selectElement, option) => {
  return window.avv_select_get_all_options(selectElement).find(option)
}
window.avv_select_find_option = _avv_select_find_option

const _avv_select_option = (
  avvOption: HTMLOptionElement | null,
  silent = false,
  avvSelect?: HTMLElement | null | undefined
) => {
  if (avvOption == null) {
    if (avvSelect == null)
      throw new Error(
        'When avvOption is null then avvSelect argument is required.'
      )
  }

  if (avvOption && avvOption.classList.contains('disabled')) {
    console.warn('Trying to select option with disabled class')
    return
  }

  const select =
    avvSelect ?? (ascendantNodeByTag('AVV-SELECT', avvOption, 3) as HTMLElement)
  if (select != null) {
    const options = window.avv_select_get_all_options(select)
    const noSelected = select.hasAttribute('no-selected')
    const noDisplayUpdate = select.hasAttribute('no-display-update')
    const isMultiselect =
      select.hasAttribute('multi') && select.getAttribute('multi') !== 'false'

    if (!noSelected) {

      if (!isMultiselect) {
        const current_selected = options.find(op => op.getAttribute("selected") === "true")
        if (!silent && current_selected === avvOption) return;

        if (current_selected != null) {
          current_selected.removeAttribute("selected");
        }
      }

      let span = select.querySelector(':scope > span')
      let spanClass = select.dataset.spanClass

      if (span == null) {
        span = document.createElement('span')

        if (spanClass) {
          span.classList.add(...spanClass.split(' '))
        }

        if (select.id) {
          span.id = `${select.id}-display`
        }
        select.insertBefore(span, select.children.item(0))
      }

      if (
        avvOption != null &&
        avvOption.getAttribute('selectable') != 'false'
      ) {
        // Allows us to put other content inside <avv-option>
        const matchText = (option) =>
          option.querySelector('.text')?.textContent ?? option.textContent

        if (isMultiselect) {
          if (avvOption.getAttribute('selected') === 'true') {
            avvOption.removeAttribute('selected')
          } else {
            avvOption.setAttribute('selected', 'true')
          }

          if (!noDisplayUpdate) {
            span.textContent = window
              .avv_select_get_all_options(select)
              .filter((el) => el.getAttribute('selected') === 'true')
              .map((el) => matchText(el))
              .join(', ')
          }
        } else {
          avvOption.setAttribute('selected', 'true')
          if (!noDisplayUpdate) {
            span.textContent = matchText(avvOption)
          }
        }
      } else if (!noDisplayUpdate) {
        const placeholder = select.getAttribute('placeholder')
        if (placeholder != null) {
          span.textContent = placeholder
        } else {
          span.textContent = 'Missing placeholder'
        }
      }
    }
    if (!silent) window.avv_weak_map.get(select).fireEvent('select', avvOption)
  }
}
window.avv_select_option = _avv_select_option

const _avv_select_set_display = (text, select, useHtml = false) => {
  let span = select.querySelector(':scope > span')
  if (span == null) {
    span = document.createElement('span')
    if (select.id) {
      span.id = `${select.id}-display`
    }
    select.insertBefore(span, select.children.item(0))
  }
  if (useHtml) {
    const matchText = (option) =>
      option.querySelector('.text')?.textContent ?? option.textContent
    const isMultiSelect = select.hasAttribute('multi')
    if (isMultiSelect) {
      span.innerHTML =
        window
          .avv_select_get_all_options(select)
          .filter((el) => el.getAttribute('selected') === 'true')
          .map((el) => matchText(el))
          .join(', ') || select.getAttribute('placeholder')
    } else
      span.innerHTML =
        window
          .avv_select_get_all_options(select)
          .find((el) => el.getAttribute('selected') === 'true')?.innerHTML ||
        select.getAttribute('placeholder')
  } else span.textContent = text
}
window.avv_select_set_display = _avv_select_set_display

const _avv_select_add_option = (select, text, value) => {
  const avvOption = document.createElement('avv-option')
  avvOption.textContent = text
  avvOption.setAttribute('value', value ?? text)
  avvOption.setAttribute('custom', 'true')
  select.querySelector('.menu')?.appendChild(avvOption)
  window.avv_select_option(avvOption)
}
window.avv_select_add_option = _avv_select_add_option

const _avv_select_remove_option = (select, value: string) => {
  const option = select.querySelector(`avv-option[value="${value}"]`)
  option.remove()
}
window.avv_select_remove_option = _avv_select_remove_option

const _avv_select_get_all_options = (selectElement: HTMLElement) => {
  const all = selectElement.querySelectorAll('avv-option')
  if (all != null) return Array.from(all)
  return all
}
window.avv_select_get_all_options = _avv_select_get_all_options
const minimumOpenDelay = 300

const _avv_select_toggle = (
  selectElement: HTMLSelectElement,
  originalEvent
) => {
  if (window.avv_select_is_open(selectElement)) {
    window.avv_select_close(selectElement, originalEvent)
  } else if (!selectElement.hasAttribute('data-open-blocked')) {
    originalEvent.stopPropagation()
    window.avv_select_open(selectElement, originalEvent)
  }
}
window.avv_select_toggle = _avv_select_toggle

const _avv_select_open = (selectElement: HTMLSelectElement, originalEvent) => {
  if (!window.avv_select_is_open(selectElement)) {
    window.avv_open_set.add(selectElement)
    selectElement.dataset.open = 'true'
    selectElement.setAttribute('aria-expanded', 'true')
    selectElement
      .querySelector('.menu')
      ?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    window.avv_weak_map.get(selectElement)?.fireEvent('open', originalEvent)
  }
}
window.avv_select_open = _avv_select_open

const _avv_select_close = (
  selectElement: HTMLSelectElement,
  originalEvent: Event = undefined
) => {
  if (window.avv_select_is_open(selectElement)) {
    selectElement.setAttribute('data-open-blocked', 'true')
    setTimeout(() => selectElement.removeAttribute('data-open-blocked'), 300)

    selectElement.dataset.open = 'false'
    selectElement.setAttribute('aria-expanded', 'false')
    window.avv_weak_map.get(selectElement)?.fireEvent('close', originalEvent)
    if (originalEvent instanceof MouseEvent) {
      window.addEventListener(
        'click',
        () => {
          window.avv_open_set.delete(selectElement)
        },
        { once: true }
      )
    } else {
      window.avv_open_set.delete(selectElement)
    }
  }
}
window.avv_select_close = _avv_select_close

const _avv_select_is_open = (selectElement) => {
  return selectElement.dataset.open === 'true'
}
window.avv_select_is_open = _avv_select_is_open

const _avv_select_any_menu_open = () => {
  return window.avv_open_set.size > 0
}

window.avv_select_any_menu_open = _avv_select_any_menu_open

export default class Elements { }

// ----------------------------- avv-select
declare global {
  interface Window {
    avv_dialog: typeof _avv_dialog
    avv_select_is_open: typeof _avv_select_is_open
    avv_select_any_menu_open: typeof _avv_select_any_menu_open
    avv_select_close: typeof _avv_select_close
    avv_select_open: typeof _avv_select_open
    avv_select_toggle: typeof _avv_select_toggle
    avv_select_set_display: typeof _avv_select_set_display
    avv_select_get_all_options: typeof _avv_select_get_all_options
    avv_select_add_option: typeof _avv_select_add_option
    avv_select_option: typeof _avv_select_option
    avv_select_find_option: typeof _avv_select_find_option
    avv_select_get_selected: typeof _avv_select_get_selected
    avv_select_register: typeof _avv_select_register
    avv_open_set: typeof _avv_open_set
    avv_dialog_manager: typeof _avv_dialog_manager
    avv_weak_map: typeof _avv_weak_map
    avv_listen: typeof _avv_listen
  }
  const avv_dialog: typeof _avv_dialog
  const avv_select_is_open: typeof _avv_select_is_open
  const avv_select_any_menu_open: typeof _avv_select_any_menu_open
  const avv_select_close: typeof _avv_select_close
  const avv_select_open: typeof _avv_select_open
  const avv_select_toggle: typeof _avv_select_toggle
  const avv_select_set_display: typeof _avv_select_set_display
  const avv_select_get_all_options: typeof _avv_select_get_all_options
  const avv_select_add_option: typeof _avv_select_add_option
  const avv_select_option: typeof _avv_select_option
  const avv_select_find_option: typeof _avv_select_find_option
  const avv_select_get_selected: typeof _avv_select_get_selected
  const avv_select_register: typeof _avv_select_register
  const avv_open_set: typeof _avv_open_set
  const avv_dialog_manager: typeof _avv_dialog_manager
  const avv_weak_map: typeof _avv_weak_map
  const avv_listen: typeof _avv_listen
}
